<template>
    <div class="my-3">
        <h4 class="text-center my-3">AVAILABLE UNIVERSITIES</h4>
        <div
        class="list-group list-group-light col-md-6 center text-center mx-auto"
        v-for="universite in items"
        :key="universite.id"
        >
        <router-link  :to="`/detail-university/${universite.id}/${universite.name}`">
            <div>
                <div
                class="list-group-item px-3 rounded-1 list-group-item-success mb-2 border-start border-success border-5  universite
                "
                style="cursor: pointer"
                >
                {{ universite.name }}
            </div>
        </div>
        
    </router-link>
    
    
    
</div>
</div>
</template>

<script>
export default {
    data() { },
    mounted() {
        this.get();
    },
    methods: {
        get() {
            this.getData("university_search")
            .then((response) => {
                this.$store.commit("setStateData", {
                    key: "university_search",
                    value: response.data?.universities,
                });
            })
            .catch((error) => {
                console.log(error);
            });
        },
    },
    computed: {
        items() {
            return this.$store.getters.fetchData("university_search");
        },
    },
};
</script>

<style scoped>

.universite:hover{
    background-color:aliceblue;
}
/* .list-university {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 10px;
}
.list-university-tem {
    border: 2px solid grey;
    width: 15%;
    text-align: center;
    border-radius: 6px;
    border-bottom: 20px solid #72b17c;
}

.list-university-tem:hover {
    cursor: pointer;
    border-bottom: 20px solid #1c733d;
    background: #72b17c;
    color: aliceblue;
    transition: 0.5s ease-in-out;
} */
</style>
