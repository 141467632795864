import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import mixins from './mixins'
import axiosInstance from './plugins/axiosConfig'
import VueSweetalert2,{ options} from './plugins/sweetAlert'
import Vue3EasyDataTable from 'vue3-easy-data-table';
import "bootstrap"
import "bootstrap/dist/css/bootstrap.min.css"
import 'vue3-easy-data-table/dist/style.css';
import "./assets/main.css"
import 'mdb-vue-ui-kit/css/mdb.min.css';


// Configuration pour axios
const app = createApp(App)
app.component('EasyDataTable', Vue3EasyDataTable);
app.config.globalProperties.axios =  { ...axiosInstance }
app
.mixin(mixins)
.use(VueSweetalert2,options)
.use(store)
.use(router)
.mount('#app')
