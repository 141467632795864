<template>
    <div>
    <route-component></route-component>
        <router-view/>
        <admin-footer/>
    </div>
</template>

<script>
import AdminFooter from './AdminFooter.vue'
import RouteComponent from './RouteComponent.vue'
    export default {
  components: { RouteComponent, AdminFooter },
        
    }
</script>

<style lang="scss" scoped>

</style>