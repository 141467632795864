<template>
    <div>
        <h3 class="text-center my-1">Université</h3>

        <list-universite />
    </div>
</template>

<script>
import ListUniversite from "../components/university/ListUniversite.vue";

export default {
    components: { ListUniversite },

    data() {},
};
</script>

<style scoped></style>
