<template>
    <div>
        <div class="container-fluid">
            <header-section-filter />
            <h4 class="text-center my-3">Liste des Livres</h4>
            <div class="row row-cols-1 row-cols-md-4 g-2">
                <livre-card
                    v-for="livre in livresListes"
                    :livre="livre"
                    :key="livre.id"
                />
            </div>
        </div>
    </div>
</template>

<script>
import HeaderSectionFilter from "./HeaderSectionFilter.vue";
import LivreCard from "./LivreCard.vue";
export default {
    components: { LivreCard, HeaderSectionFilter },
    data() {
        return {
            searchKey: "",
        };
    },
    mounted() {
        this.get();
    },
    methods: {
        get() {
            this.getData("read_livres")
                .then((response) => {
                    this.$store.commit("setStateData", {
                        key: "livresListe",
                        value: response.data,
                    });
                })
                .catch((error) => {
                    console.error(error);
                });
        },
    },
    computed: {
        livresListes() {
            return this.$store.getters.fetchData("filterLivres");
        },
    },
};
</script>

<style scoped>
.cards {
    display: flex;
    justify-content: space-between;

    flex-wrap: wrap;
    gap: 20px 0;
}
.main-container {
    padding: 30px;
    margin: 0 40px;
}
@media (max-width: 900px) {
    .cards {
        display: grid;
        margin: 0 0;
    }
}
</style>
