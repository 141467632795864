<template>
    <main>
        <div class="container">
            <header-page />
            <section-header/>
            <footer-web />
        </div>
    </main>
</template>

<script>
import SectionHeader from '../components/web/accueil/SectionHeader.vue'
import FooterWeb from '../components/web/FooterWeb.vue'
import HeaderPage from '../components/web/HeaderPage.vue'

export default {
    components: { HeaderPage, FooterWeb, SectionHeader },

    data() {
        return {
            livre: []
        }
    },
    methods: {

    }
}
</script>

<style scoped>

</style>