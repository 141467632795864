<template>
    <nav class="navbar navbar-dark bg-success fixed-top">
        <div class="container-fluid">
            <a class="navbar-brand" href="#"
                >Administration de la bibliotheque</a
            >
            <button
                class="navbar-toggler"
                type="button"
                data-bs-toggle="offcanvas"
                data-bs-target="#offcanvasDarkNavbar"
                aria-controls="offcanvasDarkNavbar"
            >
                <i class="fa-solid fa-bars text-white"></i>
            </button>
            <div
                class="offcanvas offcanvas-end text-bg-dark bg-success"
                tabindex="-1"
                id="offcanvasDarkNavbar"
                aria-labelledby="offcanvasDarkNavbarLabel"
            >
                <div class="offcanvas-header bg-success">
                    <h5 class="offcanvas-title" id="offcanvasDarkNavbarLabel">
                        My Green Library
                    </h5>
                    <button
                        type="button"
                        class="btn-close btn-close-white"
                        data-bs-dismiss="offcanvas"
                        aria-label="Close"
                    ></button>
                </div>
                <div class="offcanvas-body">
                    <ul
                        class="navbar-nav justify-content-end flex-grow-1 pe-3 bg-success"
                    >
                        <li class="nav-item">
                            <router-link to="university">
                                <div class="nv-item">
                                    <span class="px-2 fs-4">
                                        <i
                                            class="fa-solid fa-building-columns"
                                        ></i>
                                    </span>
                                    <span class="fs-5">Université</span>
                                </div>
                            </router-link>
                        </li>
                        <li>
                            <router-link to="faculte">
                                <div class="nv-item">
                                    <span class="px-2 fs-4">
                                        <i
                                            class="fa-solid fa-chalkboard-user"
                                        ></i>
                                    </span>
                                    <span class="fs-5">Domaine</span>
                                </div>
                            </router-link>
                        </li>
                        <!-- <li>
                            <router-link to="department">
                                <div class="nv-item">
                                    <span class="px-2 fs-4">
                                        <i class="fa-solid fa-swatchbook"></i>
                                    </span>
                                    <span class="fs-5">Départment</span>
                                </div>
                            </router-link>
                        </li> -->
                            <!-- <li>
                            <router-link to="institut">
                                <div class="nv-item">
                                    <span class="px-2 fs-4">
                                        <i
                                            class="fa-solid fa-building-columns"
                                        ></i>
                                    </span>
                                    <span class="fs-5">Institut</span>
                                </div>
                            </router-link>
                        </li> -->
                        <li v-if="isAdmin">
                            <router-link to="/tags">
                                <div class="nv-item">
                                    <span class="px-2 fs-4">
                                        <i
                                            class="fa-solid fa-arrow-turn-up"
                                        ></i>
                                    </span>
                                    <span class="fs-5">Tags</span>
                                </div>
                            </router-link>
                        </li>
                        <li v-if="isAdmin">
                            <router-link to="category-livre">
                                <div class="nv-item">
                                    <span class="px-2 fs-4">
                                        <i class="fa-solid fa-book-atlas"></i>
                                    </span>
                                    <span class="fs-5"
                                        >Catégorie des Livres</span
                                    >
                                </div>
                            </router-link>
                        </li>
                        <li>
                            <router-link to="livre">
                                <div class="nv-item">
                                    <span class="px-2 fs-4">
                                        <i
                                            class="fa-solid fa-book-open-reader"
                                        ></i>
                                    </span>
                                    <span class="fs-5">Livres</span>
                                </div>
                            </router-link>
                        </li>
                        <!-- <li>
                            <router-link to="bibliotheque">
                                <div class="nv-item">
                                    <span class="px-2 fs-4">
                                        <i class="fa-solid fa-book-open"></i>
                                    </span>
                                    <span class="fs-5">Bibliothèque</span>
                                </div>
                            </router-link>
                        </li> -->
                        <li v-if="isAdmin">
                            <router-link to="users">
                                <div class="nv-item">
                                    <span class="px-2 fs-4">
                                        <i class="fa-solid fa-user"></i>
                                    </span>
                                    <span class="fs-5">Utilisateurs</span>
                                </div>
                            </router-link>
                        </li>
                        <li class="current">
                            <span class="fs-4">
                                <i class="fas fa-user-circle"></i>
                            </span>
                            <span class="current-user mx-3 fs-4">
                                <strong>{{
                                    $store?.state?.user?.user?.name
                                }}</strong>
                            </span>
                            <button
                                class="border-0 px-2 bg-white text-green py-2 l"
                                @click="logout"
                                title="déconnexion"
                            >
                                <i
                                    class="fa-solid fa-arrow-right-from-bracket"
                                ></i>
                                Logout
                            </button>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </nav>

    <!-- actual navbar -->

    <div class="navbar-container">
        <ul class="navbar"></ul>
    </div>
</template>

<script>
export default {
    methods: {
        logout() {
            this.postData("logout")
                .then((response) => {})
                .catch((error) => {});
            this.$store.commit("logout");
            window.location.href = "/";
        },
    },
};
</script>

<style scoped>
.navbar-container {
    margin-top: 40px;
}

.nv-item {
    padding: 3px 6px;
    border-radius: 5px;
}
.nv-item:hover {
    background-color: white;
    color: green;
}

ul {
    list-style-type: none;
    display: flex;
    justify-content: start;
    align-items: start;
        align-content: start;
    gap: 10px;
}
.navbar li {
    display: flex;
    justify-content: space-evenly;
    font-size: 18px;
    color: white;
}
.navbar a {
    text-decoration: none;
}

.navbar li img {
    margin-top: 10px;
}
.navbar li span {
    padding: 10px 0px;
    font-size: 16px;
}
.current {
    display: flex;
    justify-content: center;
    align-items: center;
}
.lg-btn {
    border: none;
    margin-left: 5px;
    padding: 5px;
}
li a {
    color: white;
    font-family: "Montserrat", sans-serif;
}
li a:hover {
    color: gray;
}
.header-icon {
    padding-bottom: 14px;
    margin-right: 6px;
}
.l {
    border-radius: 3px;
    color: green;
}
</style>
