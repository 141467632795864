<template>
    <h4 class="text-center my-2">Que cherche vous...?</h4>
    <div class="input-group">
        <div class="form-outline">
            <input type="text" class="form-control" v-model="searchKey" />
            <label class="form-label text-dark" for="form1">
                Dis moi ce que vous cherche ici...
            </label>
        </div>
        <button type="button" class="btn btn-success">
            <i class="fas fa-search"></i>
        </button>
    </div>

    <div>
        <div class="row my-3">
            <div class="form-group col-6">
                <div
                    class="input-group p-3 d-flex justify-content-center align-items-center"
                >
                    <label
                        class="input-group-text text-bg-success text-white"
                        for="inputGroupSelect01"
                        >Université</label
                    >
                    <select
                        class="form-select"
                        id="inputGroupSelect01"
                        v-model="university_id"
                    >
                        <option selected>Selectionne une université</option>
                        <option
                            v-for="niversity in universities"
                            :key="niversity.id"
                            v-bind:value="niversity.id"
                        >
                            {{ niversity.name }}
                        </option>
                    </select>
                </div>
            </div>

            <div class="form-group col-6">
                <div
                    class="input-group p-3 d-flex justify-content-center align-items-center"
                >
                    <select
                        class="form-select"
                        id="inputGroupSelect01"
                        v-model="departement_id"
                    >
                        <option selected>Selectionne un département</option>
                        <option
                            value=""
                            v-for="department in departments"
                            :value="department.id"
                            :key="department.id"
                        >
                            {{ department.name }}
                        </option>
                    </select>
                    <label
                        class="input-group-text text-bg-success text-white"
                        for="inputGroupSelect01"
                    >
                        Département
                    </label>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            searchKey: "",
            university_id: "",
            faculte_id: "",
            category_id: "",
            departement: "",
            departement_id: "",
            livres: [],
        };
    },
    watch: {
        searchKey(value) {
            const x = this.searchInArray(this.livresListes, value);
            this.setSearchData(x);
        },
        university_id(_val) {
          
                const x = this.livresListes.filter(
                    (x) => x.university_id === _val
                );
                this.setSearchData(x);
                if( isNaN(_val) ){
                    this.initialize();
                }
        },
        departement_id(value) {
            
        },
        category_id(_val) {
            alert(_val);
        },
        faculte_id(_val) {
            alert(_val);
        },
        livresListes(val) {
            this.setSearchData(val);
        },
    },
    mounted() {
        this.get("university_search");
    },
    methods: {
        initialize() {
            this.setSearchData(this.livresListes);
        },
        setSearchData(v) {
            this.$store.commit("setStateData", {
                key: "filterLivres",
                value: v,
            });
        },
        get(url) {
            this.getData(url)
                .then((response) => {
                    this.$store.commit("setStateData", {
                        key: url,
                        value: response.data,
                    });
                })
                .catch((error) => {
                    console.error(error);
                });
        },
    },
    computed: {
        livresListes() {
            return this.$store.getters.fetchData("livresListe");
        },
        filterLivres() {
            return this.$store.getters.fetchData("filterLivres");
        },
        universities() {
            return this.$store.getters.fetchData("university_search")[
                "universities"
            ];
        },
        facultes() {
            return this.$store.getters.fetchData("university_search")[
                "facultes"
            ];
        },
        categories() {
            return this.$store.getters.fetchData("university_search")[
                "categories"
            ];
        },
        departments() {
            return this.$store.getters.fetchData("university_search")[
                "departments"
            ];
        },
    },
};
</script>

<style scoped></style>
