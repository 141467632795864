<template>
    <div class="row d-flex justify-content-center align-items-center mx-auto">

        <div class="col-12 col-md-4 text-center center">
            <div
                class="input-group p-3 d-flex justify-content-center align-items-center"
            >
                <label class="input-group-text text-bg-success text-white"
                    >Search value:
                </label>
                <input type="text" class="form-control" v-model="searchValue" />
            </div>
        </div>
        <div class="col-12 col-md-4 text-center center">
            <button class="btn btn-success" @click="addElement">Ajouter</button>
        </div>
    </div>

    <div class="data-table">
        <EasyDataTable
            :headers="headers"
            :items="items"
            :search-field="searchField"
            :search-value="searchValue"
            buttons-pagination
            table-class-name="customize-table"
            :rows-per-page="10"
            alternating
        >
        <template #item-faculte_id="item">
        {{ item.faculte?.name }}
        </template>
            <template #item-file_url="item">
                <div v-if="item.file_url.length > 3">
                    <a
                        target="_blank"
                        :href="item.file_url"
                        class="text-success"
                    >
                        <i class="fas fa-book-open"></i> Lire
                    </a>
                    <!-- <button @click="visualiserLeFichier">Visualiser</button> -->
                </div>
            </template>
            <template #item-operation="item">
                <!-- <button @click="edit" class="btn btn-primary">
                    <i class="fa-regular fa-pen-to-square"></i>
                </button> -->
                <button @click="deleteElement" class="btn btn-danger">
                    <i class="fa-solid fa-trash-can"></i>
                </button>
                <button
                    @click="showAddFile(item)"
                    title="ajouter un document"
                    class="btn btn-success"
                >
                    <i class="fa-solid fa-file-arrow-up"></i>
                </button>
            </template>
        </EasyDataTable>
        <modal-component :modalActive="modalActive" @close="close">
            <upload-book :livre="livreId" @close="close" />
        </modal-component>
    </div>
</template>

<script>
import ModalComponent from "../global/ModalComponent.vue";
import UploadBook from "./UploadBook.vue";
import folder from "../../assets/icons/folder.png";
export default {
    components: { ModalComponent, UploadBook },
    props: ["headers", "items"],
    data() {
        return {
            folder: folder,
            searchField: "",
            searchValue: "",
            livreId: "",
            modalActive: false,
        };
    },
    methods: {
        visualiserLeFichier() {
            alert("Je suis en bonne santé");
        },
        close() {
            this.$emit("reload");
            this.modalActive = !this.modalActive;
        },
        showAddFile(item) {
            this.livreId = item;
            this.modalActive = !this.modalActive;
        },
        deleteElement() {
            alert("Delete");
        },
        edit() {
            alert("Edit");
        },
        addElement() {
            this.$emit("addElement");
        },
    },
};
</script>

<style>
.text-right {
    text-align: right;
}
.customize-table {
    --easy-table-body-row-font-size: 17px;
    --easy-table-header-font-size: 18px;
}
/* .customize-table {
    --easy-table-border: 1px solid #445269;
    --easy-table-row-border: 1px solid #445269;
    --easy-table-header-font-size: 14px;
    --easy-table-header-height: 50px;
    --easy-table-header-font-color: #c1cad4;
    --easy-table-header-background-color: #2d3a4f;

    --easy-table-header-item-padding: 10px 15px;

    --easy-table-body-even-row-font-color: #fff;
    --easy-table-body-even-row-background-color: #4c5d7a;

    --easy-table-body-row-font-color: #c0c7d2;
    --easy-table-body-row-background-color: #2d3a4f;
    --easy-table-body-row-height: 50px;
    --easy-table-body-row-font-size: 17px;

    --easy-table-body-row-hover-font-color: #2d3a4f;
    --easy-table-body-row-hover-background-color: #eee;

    --easy-table-body-item-padding: 10px 15px;

    --easy-table-footer-background-color: #2d3a4f;
    --easy-table-footer-font-color: #c0c7d2;
    --easy-table-footer-font-size: 14px;
    --easy-table-footer-padding: 0px 10px;
    --easy-table-footer-height: 50px;

    --easy-table-rows-per-page-selector-width: 70px;
    --easy-table-rows-per-page-selector-option-padding: 10px;

    --easy-table-scrollbar-track-color: #2d3a4f;
    --easy-table-scrollbar-color: #2d3a4f;
    --easy-table-scrollbar-thumb-color: #4c5d7a;
    ;
    --easy-table-scrollbar-corner-color: #2d3a4f;

    --easy-table-loading-mask-background-color: #2d3a4f;
} */
</style>
