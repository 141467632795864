<template>
    <div class="text-center p-3 text-black">
        © {{ new Date().getFullYear() }} Copyright:
        <a class="text-black" href="#">My Green Library</a>
    </div>
</template>

<script>
export default {};
</script>

<style scoped></style>
