<template>
    <main>
        <base-component v-if="!isLogidIn" />
        <admin-component v-else />
    </main>
</template>

<script>
import AdminComponent from "./components/global/AdminComponent.vue";
import BaseComponent from "./components/global/BaseComponent.vue";
import Home from "./views/Home.vue";

export default {
    components: { Home, BaseComponent, AdminComponent },

    data() {
        return {};
    },
    mounted() {
        this.$store.commit("initializeStore");
    },
    computed: {
        isLogidIn() {
            return !!this.$store.state.user;
        },
    },
};
</script>

<style>
#app {
    font-family: Roboto, Helvetica, Arial, sans-serif;
}
</style>
