import { createStore } from 'vuex'
import router from '../router'

export default createStore({
    state: {
        // baseUrl: 'http://127.0.0.1:8000/api/',
        baseUrl: 'http://library.aiph.bi/bibliotheque/public/api/',
        user: null,
        fectchData: {
        }
    },
    mutations: {
        setStateData(state, object) {
            state.fectchData[object.key] = object.value;
        },
        login(state, user) {
            state.user = user;
            localStorage.setItem('user', JSON.stringify(state.user));
            //router.push("/")
            window.location.href = "/university"
        },
        logout(state) {
            state.user = null;
            localStorage.removeItem('user');
        },
        initializeStore(state) {
            if (localStorage.getItem('user')) {
                state.user = JSON.parse(localStorage.getItem('user'));
            } else {
                this.commit("logout");
            }
        }
    },
    actions: {
    },
    modules: {
    },
    getters: {
        user: state => {
            if (state.user === null) {
                return JSON.parse(localStorage.getItem('user'))
            }
            return state.user
        },
        token: (state, getters) => getters?.user?.token,

        fetchData: (state) => (key) => {
            if (!state.fectchData[key]) {
                state.fectchData[key] = [];
            }
            return state.fectchData[key]
        }

    },
    computed: {

    }
})
