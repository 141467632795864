<template>
    <div>
    <div class="main-container">
        <div class="heading">
            <h1 class="heading__title">Titre du livre : {{ livre.title }}</h1>
            <p class="heading__credits"><a class="heading__link" target="_blank" href="#">
           
            {{ livre.description }}</a></p>

        </div>
        
    </div>

    </div>
</template>

<script>
    export default {
        props:['livre']
        
    }
</script>

<style  scoped>
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica,
        Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

.main-container {
    padding: 30px;
}

/* HEADING */

.heading {
    text-align: center;
}

.heading__title {
    font-weight: 600;
}

.heading__credits {
    margin: 10px 0px;
    color: #888888;
    font-size: 25px;
    transition: all 0.5s;
}

.heading__link {
    text-decoration: none;
}

.heading__credits .heading__link {
    color: inherit;
}

/* CARDS */



@media (max-width: 1600px) {
    .cards {
        justify-content: center;
    }
}
</style>