<template>
    <div>
    <base-home-page/>
    </div>
</template>
<script>

import BaseHomePage from '../components/web/BaseHomePage.vue'
    export default {
        components: {  BaseHomePage },
    }
</script>

<style lang="scss" scoped>

</style>