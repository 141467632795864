<template>
    <div class="row d-flex justify-content-center align-items-center mx-auto">
     
        <div class="col-12 col-md-8 text-right">
            <div
                class="input-group p-3 d-flex justify-content-right align-items-right"
            >
                <label class="input-group-text text-bg-success text-white"
                    >Valeur rechercher:
                </label>
                <input type="text" class="form-control" v-model="searchValue" />
            </div>
        </div>
        <div class="col-12 col-md-4 text-center center">
            <button class="btn btn-success" @click="addElement">Ajouter</button>
        </div>
    </div>

    <div class="data-table">
        <EasyDataTable
            :headers="headers"
            :items="items"
            :search-field="searchField"
            :search-value="searchValue"
            buttons-pagination
            table-class-name="customize-table"
            :rows-per-page="10"
            alternating
        >
            <template #item-operation="item">
                <div class="operation-button">
                    <button
                        @click="editElement(item)"
                        title="Modifier"
                        class="btn btn-primary"
                    >
                        <i class="fa-regular fa-pen-to-square"></i>
                    </button>
                    <button
                        @click="deleteElement(item)"
                        title="Supprimer"
                        class="btn btn-danger"
                    >
                        <i class="fa-solid fa-trash-can"></i>
                    </button>
                </div>
            </template>
        </EasyDataTable>
    </div>
</template>

<script>
export default {
    props: ["headers", "items", "tableUrl"],
    data() {
        return {
            searchField: "",
            searchValue: "",
        };
    },
    methods: {
        async deleteElement(item) {
            const { value: response } = await this.confirmDelete(
                "Suppression",
                "Voulez-vous vraiment supprimer ? ",
                "OUI"
            );
            if (response) {
                this.deleteData(`${this.tableUrl}/${item.id}`);
            }
            this.$emit("deleteElement", item);
        },
        editElement(item) {
            this.$emit("editElement", item);
        },
        addElement() {
            this.$emit("addElement");
        },
    },
};
</script>

<style>
.operation-button {
    display: flex;
    justify-content: space-evenly;
}
.text-right {
    text-align: right;
}
.customize-table {
    --easy-table-body-row-font-size: 12px;
    --easy-table-header-font-size: 12px;
}
.searchitem {
    padding: 2px 2px;
}
.searchitems {
    padding: 2px 2px;
}
.btn-add {
    font-family: "Montserrat", sans-serif;
    padding: 5px 5px;
    margin: 0px 0px 5px 5px;
    color: white;
    background: #237641;
    border: none;
}
.serchspan {
    font-family: "Montserrat", sans-serif;
    font-size: 18px;
    font-weight: bold;
    color: rgb(78, 78, 78);
}
.customize-table {
    --easy-table-border: 1px solid white;
    --easy-table-padding: 10px;
    --easy-table-row-border: 1px solid white;
    --easy-table-header-font-size: 14px;
    --easy-table-header-height: 15px;
    --easy-table-header-font-color: white;
    --easy-table-header-background-color: #32ac5f;
    --easy-table-header-item-padding: 5px 5px;
    --easy-table-footer-background-color: #2d3a4f;
    --easy-table-footer-font-color: #c0c7d2;
    --easy-table-footer-font-size: 12px;
    --easy-table-footer-padding: 0px 5px;
    --easy-table-footer-height: 50px;

    --easy-table-rows-per-page-selector-width: 70px;
    --easy-table-rows-per-page-selector-option-padding: 10px;

    --easy-table-scrollbar-track-color: #2d3a4f;
    --easy-table-scrollbar-color: #2d3a4f;
    --easy-table-scrollbar-thumb-color: #4c5d7a;
    --easy-table-scrollbar-corner-color: #2d3a4f;

    --easy-table-loading-mask-background-color: gray;
}

.data-table {
    padding: 15px;
}
</style>
