<template>
    <div>
        <h3 class="text-center my-4">Niveau</h3>
        <table-liste-simple
            :items="items"
            :headers="headers"
            tableUrl="niveau"
            @deleteElement="get"
        />
    </div>
</template>

<script>
import TableListeSimple from "../global/TableListeSimple.vue";
export default {
    components: { TableListeSimple },
    mounted() {
        this.get();
    },
    methods: {
        get() {
            this.getData("niveau")
                .then((response) => {
                    this.$store.commit("setStateData", {
                        key: "niveau",
                        value: response.data.data,
                    });
                })
                .catch((error) => {
                    console.log(error);
                });
        },
    },
    computed: {
        items() {
            return this.$store.getters.fetchData("niveau");
        },
        headers() {
            return [
                {
                    text: "#",
                    value: "id",
                },
                {
                    text: "name",
                    value: "name",
                },
                {
                    text: "description",
                    value: "description",
                },
                {
                    text: "faculte_id",
                    value: "faculte_id",
                },
                {
                    text: "opération",
                    value: "operation",
                },
            ];
        },
    },
};
</script>

<style scoped>
.uni {
    text-align: center;
    padding: 5px;
    margin: 5px 15px;
    background: #237641;
    color: white;
}
</style>
