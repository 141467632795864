<template>
    <main>
        <div class="container">
            <header-page></header-page>
            <livre-liste />
            <footer-web/>
        </div>
        
    </main>
</template>

<script>
import FooterWeb from './FooterWeb.vue'
import HeaderPage from './HeaderPage.vue'
import LivreListe from './LivreListe.vue'
    export default {
  components: { LivreListe, HeaderPage, FooterWeb },
        data(){
            return {
                livre : []
            }
        },
        methods:{
            
        }
    }
</script>

<style lang="scss" scoped>

</style>