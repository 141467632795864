<template>
    <div>
        <h4>Nouveau Catégorie</h4>

        <form action="">
            <div class="form-group">
                <label for="">Catégorie des Livres</label>
                <input class="form-control form-control-sm" type="text" v-model="form.name" />
            </div>
            <!-- <div class="form-group">
                <label for="">Université</label>
                <select
                    name=""
                    id=""
                    v-model="form.university_id"
                    class="form-control"
                >
                    <option value=""></option>
                    <option v-for="un in universities" :value="un.id">
                        {{ un.name }}
                    </option>
                </select>
            </div> -->
            <!-- <div class="form-group">
                <label for="">Déscription</label>
                <textarea
                    class="form-control form-control-sm"
                    name=""
                    v-model="form.description"
                ></textarea>
            </div> -->
            <div v-if="errorMessage" class="text-danger">
                {{ errorMessage }}
            </div>

            <div class="d-flex align-items-center justify-content-center my-3">
                <button @click.prevent="save" class="btn btn-success">
                    <span> Enregistrer </span>
                </button>
            </div>
        </form>
    </div>
</template>

<script>
export default {
    props: ["selectItem"],
    data() {
        return {
            form: {
                name: "",
                description: "desciption",
                university_id: "1",
                id: "",
            },
            errorMessage: "",
            universities: "",
        };
    },
    watch: {
        selectItem(val) {
            this.form.name = val.name;
            this.form.description = val.description;
            this.form.university_id = val.university_id;
            this.form.id = val.id;
        }
    },
    mounted() {
        this.getData("university")
            .then((result) => {
                this.universities = result.data.data;

                this.$emit("close");
            })
            .catch((e) => {
                console.log(e);
            });
    },
    methods: {
        save() {
            if (!this.form.university_id) {
                this.form.university_id =
                    this.$store.state.user.user.university_id;
            }

            if (this.form.id != "") {
                this.putData("category-livre/" + this.form.id, this.form)
                    .then((response) => {
                        this.$emit("close");
                        this.form = {};
                    })
                    .catch((error) => {
                        this.errorMessage = error.response.data.message;
                    });

            } else {
                this.postData("category-livre", this.form)
                    .then((response) => {
                        this.$emit("close");
                        this.form = {};
                    })
                    .catch((error) => {
                        this.errorMessage = error.response.data.message;
                    });
            }


        },
    },
    computed: {},
};
</script>

<style lang="scss" scoped></style>
