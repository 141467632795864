<template>
    <div>
        <form action="">
            <h5>Ajouter une Institut</h5>
            <div class="form-group">
                <label for="">Nom de l'Institut</label>
                <input
                    type="text"
                    class="form-control form-control-sm"
                    v-model="form.name"
                />
            </div>
            <div class="form-group">
                <label for="">Faculté</label>

                <select
                    name=""
                    class="form-control form-control-sm"
                    v-model="form.faculte_id"
                >
                    <option v-for="fac in facultes" :key="fac" :value="fac.id">
                        {{ fac.name }}
                    </option>
                </select>
            </div>
            <div class="text-danger">
                {{ errorMessage }}
            </div>

            <div class="d-flex align-items-center justify-content-center my-3">
                <button @click.prevent="save" class="btn btn-success">
                    <span> Ajouter </span>
                </button>
            </div>
        </form>
    </div>
</template>

<script>
export default {
    data() {
        return {
            form: {
                name: "",
                description: "",
                faculte_id: "",
                university_id: "",
            },
            errorMessage: "",
            facultes: [],
        };
    },
    mounted() {
        this.getData("faculte")
            .then((result) => {
                this.facultes = result.data.data;
            })
            .catch((error) => {});
    },
    methods: {
        save() {
            if (!this.form.university_id) {
                this.form.university_id =
                    this.$store.state.user.user.university_id;
            }
            this.postData("institut", this.form)
                .then((response) => {
                    this.$emit("close");
                    console.log(response);
                })
                .catch((error) => {
                    this.errorMessage = error.response.data.message;
                    console.log(error);
                });
        },
    },
};
</script>

<style lang="scss" scoped></style>
