<template>
    <div class="my-3">
        <h2 class="text-center">Identifiez-vous ? </h2>
        <div
            class="col-md-6 mx-auto border border-2 border-success rounded-6 my-2 p-2"
        >
            <form class="">
                <div class="mb-3">
                    <label for="email" class="form-label">Email address</label>
                    <input
                        type="email"
                        id="form2Example1"
                        class="form-control"
                        v-model="form.username"
                    />
                </div>

                <div class="mb-3">
                    <label for="password" class="form-label">Password</label>
                    <input
                        type="password"
                        class="form-control"
                        id="password"
                        v-model="form.password"
                    />
                </div>

                <div class="row mb-4">
                    <div class="col d-flex justify-content-center">
                        <div class="form-check">
                            <input
                                class="form-check-input"
                                type="checkbox"
                                checked
                            />
                            <label
                                class="form-check-label"
                                for="form2Example34"
                            >
                                Se rappeler de moi
                            </label>
                        </div>
                    </div>
                </div>
                <button
                    @click="login"
                    type="button"
                    class="btn btn-success btn-block mb-4"
                >
                    Connexion
                </button>
            </form>
        </div>
    </div>
</template>

<script>
import TemplateForm from "./login/TemplateForm.vue";
export default {
    components: { TemplateForm },
    data() {
        return {
            form: {
                username: "",
                password: "",
            },
            messageError: "",
        };
    },
    methods: {
        login() {
            this.postData("login", this.form)
                .then((response) => {
                    console.log(response.data.data);
                    const user = response.data.data;
                    this.$store.commit("login", user);
                })
                .catch((error) => {
                    this.messageError = error.response.data.message;
                });
        },
    },
};
</script>

<style scoped>
body {
    margin-top: 20px;
    background: #eee;
}

.container {
    margin-right: auto;
    margin-left: auto;
    padding-right: 15px;
    padding-left: 15px;
    width: 100%;
}

@media (min-width: 576px) {
    .container {
        max-width: 540px;
    }
}

@media (min-width: 768px) {
    .container {
        max-width: 720px;
    }
}

@media (min-width: 992px) {
    .container {
        max-width: 960px;
    }
}

@media (min-width: 1200px) {
    .container {
        max-width: 1140px;
    }
}

.card-columns .card {
    margin-bottom: 0.75rem;
}

@media (min-width: 576px) {
    .card-columns {
        column-count: 3;
        column-gap: 1.25rem;
    }

    .card-columns .card {
        display: inline-block;
        width: 100%;
    }
}

.text-muted {
    color: #9faecb !important;
}

p {
    margin-top: 0;
    margin-bottom: 1rem;
}

.mb-3 {
    margin-bottom: 1rem !important;
}

.input-group {
    position: relative;
    display: flex;
    width: 100%;
}
.btn-connexion {
    padding: 6px 16px;
    margin-left: 10px;
    background: #237641;
    color: white;
    font-size: 20px;
    border: none;
}
.btn-connexion:hover {
    color: gray;
}
</style>
