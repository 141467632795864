<template>
    <div>
        <h3 class="text-center my-4">Bibliothèque</h3>
    </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
