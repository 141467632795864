<template>
    <div>
        <user-list/>
    </div>
</template>

<script>
import UserList from '../components/users/UserList.vue'
    export default {
  components: { UserList },
        
    }
</script>

<style lang="scss" scoped>

</style>