<template>
    <div>
        <h3 class="text-center my-1">Tags</h3>
      <tag-list/>
    </div>
</template>

<script>
import TagList from '../components/tags/TagList.vue';

export default {
    components: {TagList  },

    data() { },
};
</script>

<style scoped></style>
