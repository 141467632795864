<template>
    <div>
        <modal-component  :modalActive="isShowForm" @close="close">
            <fom-faculte @close="close" :selectItem="selectItem"/>
        </modal-component>
        <table-liste-simple
        :headers="headers"
        :items="facultes"
        tableUrl="faculte"
        @deleteElement="get"
        @editElement="editElement"
        @addElement="showForm"
        />
    </div>
</template>

<script>
import ModalComponent from '../global/ModalComponent.vue';
import TableListeSimple from '../global/TableListeSimple.vue'
import FomFaculte from './FomFaculte.vue';
export default {
    components: { TableListeSimple, FomFaculte, ModalComponent },
    props: ["selectItem"],
    data() {
        return { 
            isShowForm : false,
            selectItem : null,
        }
    },
    mounted() {
        this.get();
    },
    methods: {
        close(){
            this.get();
            this.isShowForm = false;
        },
        showForm(){
            this.isShowForm = !this.isShowForm
        },
        editElement(item) {
            this.showForm();
            this.selectItem = item;
        },
        get() {
            this.getData('faculte')
            .then(response =>{
                
                this.$store.commit('setStateData',{
                    key: 'faculte',
                    value: response.data.data
                })
            })
        }
    },
    computed:{
        facultes(){
            return this.$store.getters.fetchData('faculte')
        },
        headers() {
            return  [
            {
                text: '#',
                value: 'id'
            },
            {
                text: 'name',
                value: 'name'
            },
            // {
            //     text: 'description',
            //     value: 'description'
            // },
            // {
            //     text: 'Université',
            //     value: 'university.name'
            // },
            {
                text: 'operation',
                value: 'operation'
            },
            ]
        }
    }
    
    
}
</script>

<style lang="scss" scoped>

</style>