<template>
    <div>
        <h4>Ajouter une Departement</h4>
        <form action="">
            <div class="form-group">
                <label for="">Nom</label>
                <input
                    type="text"
                    v-model="form.name"
                    class="form-control form-control-sm"
                />
            </div>
            <div class="form-group">
                <label for="">Univerisites</label>
                <select
                    name=""
                    id=""
                    v-model="form.university_id"
                    class="form-control form-control-sm"
                >
                    <option value=""></option>
                    <option
                        v-for="un in universities"
                        :value="un.id"
                        :key="un.id"
                    >
                        {{ un.name }}
                    </option>
                </select>
            </div>

            <div class="form-group">
                <label for="">faculte_id</label>
                <select
                    name=""
                    v-model="form.faculte_id"
                    class="form-control form-control-sm"
                >
                    <option value=""></option>
                    <option
                        v-for="item in facultes"
                        :value="item.id"
                        :key="item.id"
                    >
                        {{ item.name }}
                    </option>
                </select>
            </div>

            <div class="form-group">
                <label for="">Déscription</label>
                <textarea
                    name=""
                    v-model="form.description"
                    class="form-control form-control-sm"
                ></textarea>
            </div>

            <div>
                <div v-if="errorMessage" class="text-danger">
                    {{ errorMessage }}
                </div>
            </div>
            <div class="d-flex align-items-center justify-content-center my-3">
                <button @click.prevent="save" class="btn btn-success">
                    <span>
                        {{ departement_id ? "Modifier" : "Entregistrer" }}
                    </span>
                </button>
            </div>
        </form>
    </div>
</template>

<script>
export default {
    props: ["departement"],
    data() {
        return {
            form: {
                name: "",
                description: "",
                faculte_id: "",
                university_id: "",
            },
            errorMessage: "",
            departement_id: "",
        };
    },
    watch: {
        departement(val) {
            this.form = {
                name: val?.name,
                description: val?.description,
                faculte_id: val?.faculte_id,
                university_id: val?.university_id,
            };
            this.departement_id = val?.id;
        },
    },
    mounted() {
        this.initialize();
    },
    methods: {
        initialize() {
            this.getData("university").then((response) => {
                this.$store.commit("setStateData", {
                    key: "university",
                    value: response.data.data,
                });
            });
            this.getData("faculte").then((response) => {
                this.$store.commit("setStateData", {
                    key: "faculte",
                    value: response.data.data,
                });
            });
        },
        save() {
            this.errorMessage = "";

            if (!this.form.university_id) {
                this.form.university_id =
                    this.$store.state.user.user.university_id;
            }
            if (this.departement_id) {
                this.putData("department/" + this.departement_id, this.form)
                    .then((response) => {
                        this.form = {};
                        this.$emit("close");
                    })
                    .catch((error) => {
                        this.errorMessage = error.response.data.message;
                    });
            } else {
                this.postData("department", this.form)
                    .then((response) => {
                        this.form = {};
                        this.$emit("close");
                    })
                    .catch((error) => {
                        this.errorMessage = error.response.data.message;
                    });
            }
            this.departement_id = null;
        },
    },
    computed: {
        universities() {
            return this.$store.getters.fetchData("university");
        },
        facultes() {
            return this.$store.getters.fetchData("faculte");
        },
    },
};
</script>

<style lang="scss" scoped></style>
