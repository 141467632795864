
<template>
    <div>
        <div class="container">
            <!--UPLOAD-->
            <livre-header :livre="livre"></livre-header>

            <form enctype="multipart/form-data" novalidate v-if="isInitial || isSaving">
                <h1>Importer le livre en format PDF</h1>
                <div class="dropbox">
                  
                    <input type="file" accept=".pdf" ref="file" @change="uploadFile" class="input-file"  />
                    <p v-if="isInitial">
                        vous pouvez glissez et déposer le fichier ici <br>
                        ou cliquez ici pour aller chercher le fichier là où il se trouve.
                    </p>
                    <p v-if="isSaving">
                        {{ uploadFieldName }}
                    </p>
                </div>
                <div>
                <div v-if="isLoading">
                    <div class="loading"></div>
                </div>
                <div v-if="errorMessage" class="text-danger">
                {{ errorMessage }}
                </div>
                <button @click.prevent="chargerFile" class="save-button">Charger</button>
                </div>
            </form>
        </div>
    </div>
</template>

<!-- App.vue -->
...

<!-- Javascript -->
<script>
import LivreHeader from './LivreHeader.vue';
const STATUS_INITIAL = 0,STATUS_SAVING = 1, STATUS_SUCCESS = 2, STATUS_FAILED = 3;

export default {
  components: { LivreHeader },
    props: ['livre'],
    data() {
        return {
            uploadedFiles: [],
            uploadError: null,
            currentStatus: null,
            uploadFieldName: 'Document',
            file : null,
            errorMessage: null,
            isLoading: false,
        }
    },
    methods: {

        chargerFile(){
            const formData = new FormData();
            formData.append('file', this.file);
            formData.append('livre_id', this.livre.id)
            this.errorMessage = null;
            this.isLoading = true;

            this.postData("add_livre", formData)
                .then(response => {
                    console.log(response)
                    this.$emit('close')
                    
                })
                .catch(error =>{
                    this.errorMessage = error.response.data.message
                    console.log(error.response.data.message)
                })
                .finally(resp =>{
                    this.isLoading = false
                })
        },
        uploadFile() {
            this.file = this.$refs.file.files[0];
            this.uploadFieldName = this.file.name
            this.currentStatus = STATUS_SAVING
        },
        reset() {
            // reset form to initial state
            this.currentStatus = STATUS_INITIAL;
            this.uploadedFiles = [];
            this.uploadError = null;
        },

    },
    computed: {
        isInitial() {
            return this.currentStatus === STATUS_INITIAL;
        },
        isSaving() {
            return this.currentStatus === STATUS_SAVING;
        },
        isSuccess() {
            return this.currentStatus === STATUS_SUCCESS;
        },
        isFailed() {
            return this.currentStatus === STATUS_FAILED;
        }
    },
  
    mounted() {
        this.reset();
    },
}

</script>

<!-- SASS styling -->
<style>
.loading {
    width: 100%;
    display: block;
    height: 5px;
    margin: 0px auto;
    border-radius: 2px;
    background-color: #cfcfcf;
    position: relative;
    overflow: hidden;
    z-index: 1;
}

.loading:before {
    content: '';
    height: 3px;
    width: 68px;
    position: absolute;
    -webkit-transform: translate(-34px, 0);
    -ms-transform: translate(-34px, 0);
    transform: translate(-34px, 0);
    background-color: #3b4a5e;
    border-radius: 4px;
    -webkit-animation: initial-loading 1.5s infinite ease;
    animation: animation 1.6s infinite ease;
}

@-webkit-keyframes animation {
    0% {
        left: 0
    }

    50% {
        left: 100%
    }

    100% {
        left: 0
    }
}

@keyframes animation {
    0% {
        left: 0
    }

    50% {
        left: 100%
    }

    100% {
        left: 0
    }
}
.save-button{
    display: block;
    padding: 10px;
    outline-offset: -10px;
    width: 100%;
    cursor: pointer;
    margin-top: 10px;
}
.dropbox {
    outline: 2px dashed grey; /* the dash box */
    outline-offset: -10px;
    background: lightcyan;
    color: dimgray;
    padding: 10px 10px;
    min-height: 200px; /* minimum height */
    position: relative;
    cursor: pointer;
}

.input-file {
    opacity: 0; /* invisible but it's there! */
    width: 100%;
    height: 200px;
    position: absolute;
    cursor: pointer;
}

.dropbox:hover {
    background: lightblue; /* when mouse over to the drop zone, change color */
}

.dropbox p {
    font-size: 1.2em;
    text-align: center;
    padding: 50px 0;
}
</style>
