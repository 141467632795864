<template>
    <div>
        <h4>Nouveau Tag</h4>
        <form action="">
            <div class="form-group">
                <label for="">Tag</label>
                
                <textarea class="form-control form-control-sm"  v-model="form.description"></textarea>
            </div>
            <div class="form-group">
                <label for="">Domaine</label>
                <select name="" id="" v-model="form.faculte_id" class="form-control">
                    <option value=""></option>
                    <option v-for="un in facultes" :value="un.id">
                        {{ un.name }}
                    </option>
                </select>
            </div>
            <!-- <div class="form-group">
                <label for="">Déscription</label>
                <textarea
                class="form-control form-control-sm"
                name=""
                v-model="form.description"
                ></textarea>
            </div> -->
            <div v-if="errorMessage" class="text-danger">
                {{ errorMessage }}
            </div>
            
            <div class="d-flex align-items-center justify-content-center my-3">
                <button @click.prevent="save" class="btn btn-success">
                    <span> Enregistrer </span>
                </button>
            </div>
        </form>
    </div>
</template>

<script>
export default {
    props : ["selectItem"],
    data() {
        return {
            form: {
                
                description: "",
                faculte_id: "",
                id: "",
            },
            tagId : "",
            errorMessage: "",
            facultes: "",
        };
    },
    watch: {
        selectItem(val) {
            this.form.description = val.description;
            this.form.faculte_id = val.faculte_id;
            this.form.id = val.id;
            this.tagId = val.id;
        }
    },
    mounted() {
        this.getData("faculte")
        .then((result) => {
            this.facultes = result.data.data;
            
            this.$emit("close");
        })
        .catch((e) => {
            console.log(e);
        });
    },
    methods: {
        save() {
            if (!this.form.university_id) {
                this.form.university_id =
                this.$store.state.user.user.university_id;
            }
            
            if (this.form.id != "") {
                 this.putData("tags/"+ this.form.id, this.form)
                    .then((response) => {
                        this.$emit("close");
                        this.form = {};
                    })
                    .catch((error) => {
                        this.errorMessage = error.response.data.message;
                    });
                
            } else {
                this.postData("tags", this.form)
                .then((response) => {
                    this.$emit("close");
                    this.form = {};
                })
                .catch((error) => {
                    this.errorMessage = error.response.data.message;
                });
            }
            
            
        },
    },
    computed: {},
};
</script>

<style lang="scss" scoped></style>
