<template>
    <transition name="modal-animation">
        <div v-show="modalActive" class="modal">
            <transition name="modal-animation-inner">
                <div v-show="modalActive" class="modal-inner">
                    <div class="modal-top">
                        <button
                            @click="close"
                            class="modal-modal"
                            type="button"
                        >
                        <i class="fa-solid fa-xmark"></i>
                        </button>
                    </div>
                    <slot />
                </div>
            </transition>
        </div>
    </transition>
</template>

<script>
export default {
    props: ["modalActive"],
    setup(props, { emit }) {
        const close = () => {
            emit("close");
        };
        return {
            close,
        };
    },
};
</script>

<style scoped>
.modal {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.6);
    overflow: auto;
    z-index: 10000;
}

.modal-inner {
    position: relative;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
        0 2px 4px -1px rgba(0, 0, 0, 0.06);
    background-color: #fff;
    padding: 20px 20px;
    overflow: auto;
    min-width: 500px;
}

.modal-modal {
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 0 10px;
    font-size: 20px;
    cursor: pointer;
    border-radius: 5px;
    color: white;
    background-color: #237641;
}

/* .modal-modal:hover {
    color: crimson;

} */

button {
    padding: 4px 4px;
    border: none;
    font-size: 8px;
    background-color: crimson;
    color: #fff;
    cursor: pointer;
}

.modal-animation-enter-active,
.modal-animation-leave-active {
    transition: opacity 0.3s cubic-bezier(0.52, 0.02, 0.19, 1.02);
}

.modal-animation-enter-from,
.modal-animation-leave-to {
    opacity: 0;
}

.modal-animation-inner-enter-active {
    transition: all 0.3s cubic-bezier(0.52, 0.02, 0.19, 1.02) 0.15s;
}

.modal-animation-inner-leave-active {
    transition: all 0.3s cubic-bezier(0.52, 0.02, 0.19, 1.02);
}

.modal-animation-inner-enter-from {
    opacity: 0;
    transform: scale(0.8);
}

.modal-animation-inner-leave-to {
    transform: scale(0.8);
}
</style>
