import { createRouter, createWebHistory } from 'vue-router'
import ListeLivre from '../views/Home.vue'
import University from '../views/University.vue'
import Faculte from '../views/Faculte.vue'
import DepartementList from "../components/department/DepartementList.vue"
import InstututList from "../components/institut/InstututList.vue"
import NiveauList from "../components/niveau/NiveauList"
import CategorieLivre from "../components/categoryLivre/CategorieLivre.vue"
import LivreList from "../components/livre/LivreListe.vue"
import TestComponent from "../views/TestComponent.vue"
import Bibliotheque from "../views/Bibliotheque.vue"
import Login from "../views/Login.vue"
import Home from "../views/Accueil.vue"
import User from "../views/User.vue"
import TagPage from "../views/TagPage.vue"
import UniversityDetail from "../views/UniversityDetail.vue"

const routes = [
    {
        path: '/', name: 'Home', component: Home
    },
    {
        path: '/tags', name: 'tags', component: TagPage
    },
    {
        path: '/find-book', name: 'FindPage', component: ListeLivre
    },
    {
        path: '/university', name: 'university', component: University
    },
    {
        path: '/detail-university/:id/:nameUniversiry', name: 'detail-university', component: UniversityDetail
    },
    {
        path: '/faculte', name: 'faculte', component: Faculte
    },
    {
        path: '/department', name: 'department', component: DepartementList
    },
    {
        path: '/institut', name: 'institut', component: InstututList
    },
    {
        path: '/niveau', name: 'niveau', component: NiveauList
    },
    {
        path: '/category-livre', name: 'category-livre', component: CategorieLivre
    },
    {
        path: '/livre', name: 'livre', component: LivreList
    },
    {
        path: '/test', name: 'test', component: TestComponent
    },
    {
        path: '/bibliotheque', name: 'bibliotheque', component: Bibliotheque
    },
    {
        path: '/users', name: 'users', component: User
    },
    {
        path: '/login', name: 'login', component: Login
    },
]
const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    linkActiveClass: "active",
    linkExactActiveClass: "exact-active",
})

router.beforeEach((to, from, next) => {
    // Here I will check if the user has the right permissions for using my page
    next();
    // if(to.name === 'Home'){
    //     window.location.reload();
    // }

})

export default router
