<template>
    <div>
        <h3 class="text-center my-4">Liste des département</h3>

        <table-liste-simple
            :items="departments"
            :headers="headers"
            tableUrl="department"
            @deleteElement="get"
            @addElement="showForm"
            @editElement="editElement"
        />
        <modal-component :modalActive="isShowForm" @close="showForm">
            <form-departement @close="showForm" :departement="item" />
        </modal-component>
    </div>
</template>

<script>
import ModalComponent from "../global/ModalComponent.vue";
import TableListeSimple from "../global/TableListeSimple.vue";
import FormDepartement from "./FormDepartement.vue";
export default {
    components: { TableListeSimple, ModalComponent, FormDepartement },
    data() {
        return {
            isShowForm: false,
            item: {},
        };
    },
    mounted() {
        this.get();
    },
    methods: {
        editElement(item) {
            this.item = item;
            this.showForm();
        },
        showForm() {
            this.isShowForm = !this.isShowForm;
            this.get();
        },
        get() {
            this.getData("department")
                .then((result) => {
                    this.$store.commit("setStateData", {
                        key: "department",
                        value: result.data.data,
                    });
                })
                .catch((error) => {
                    console.log(error);
                });
        },
    },
    computed: {
        departments() {
            return this.$store.getters.fetchData("department");
        },
        headers() {
            return [
                {
                    text: "id",
                    value: "id",
                },
                {
                    text: "name",
                    value: "name",
                },
                {
                    text: "Faculté",
                    value: "faculte.name",
                },
                {
                    text: "Opération",
                    value: "operation",
                },
            ];
        },
    },
};
</script>

<style scoped></style>
