import books from "../assets/icons/books.png"
import bookshelf from "../assets/icons/bookshelf.png"
import graduation from "../assets/icons/graduation.png"
import mortarboard from "../assets/icons/mortarboard.png"
import pencil from "../assets/icons/pencil.png"
import deleteIcone from "../assets/icons/delete.png"
import options from "../assets/icons/options.png"
import people from "../assets/icons/people.png"
import niveau from "../assets/icons/niveau.png"
import book from "../assets/icons/book.png"
import show from "../assets/icons/show.png"

export default {
    data() {
        return {
            books : books,
            bookshelf : bookshelf,
            graduation : graduation,
            mortarboard : mortarboard,
            edit : pencil,
            deleteIcone : deleteIcone,
            options : options,
            people : people,
            niveau : niveau,
            book : book,
            show : show,
        }
    }
}