<template>
    <footer class="bg-success text-center text-white">
        <div class="container p-4">
            <section class="">
                <div class="row">
                    <div class="col-lg-3 col-md-6 mb-4 mb-md-0">
                        <h5 class="text-uppercase">Nos </h5>
                        
                        <ul class="list-unstyled mb-0">
                            <li>
                                <a href="#!" class="text-white">Université du Burundi</a>
                            </li>
                        </ul>
                    </div>
                    <div class="col-lg-3 col-md-6 mb-4 mb-md-0">
                        <h5 class="text-uppercase">Savoir Faire</h5>
                        
                        <ul class="list-unstyled mb-0">
                            <li>
                                <a href="#!" class="text-white">Savoir Vivre</a>
                            </li>
                        </ul>
                    </div>
                    <div class="col-lg-3 col-md-6 mb-4 mb-md-0">
                        <h5 class="text-uppercase">Links</h5>
                        
                        <ul class="list-unstyled mb-0">
                            <li>
                                <a href="#!" class="text-white">Link 1</a>
                            </li>
                        </ul>
                    </div>
                    <div class="col-lg-3 col-md-6 mb-4 mb-md-0">
                        <h5 class="text-uppercase">Links</h5>
                        
                        <ul class="list-unstyled mb-0">
                            <li>
                                <a href="#!" class="text-white">Link 1</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </section>
        </div>
        <div
            class="text-center p-3"
            style="background-color: rgba(0, 0, 0, 0.2)"
                                >
                                © {{ new Date().getFullYear() }} Copyright:
                                <a class="text-white" href="#">My Green Library</a>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp
                    <a class="text-white" href="https://ubapi.aiph.bi/api/university_search">Activer la navigation sans https</a>
        </div>
    </footer>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
é