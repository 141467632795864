<template>
    <div>
        <h5>Ajout d'un utilisateur</h5>
        <form action="">
            <div class="form-group">
                <label for="">Nom et prénom</label>
                <input type="text" v-model="form.name" class="form-control form-control-sm">
            </div>
            <div class="form-group">
                <label for="">Nom d'utilisateur</label>
                <input type="text" v-model="form.username" class="form-control form-control-sm">
            </div>
            <div class="form-group">
                <label for="">Téléphone</label>
                <input type="text" v-model="form.telephone" class="form-control form-control-sm">
            </div>
            <div class="form-group">
                <label for="">Email</label>
                <input type="text" v-model="form.email" class="form-control form-control-sm">
            </div>
            <div class="form-group">
                <label for="">Mot de passe</label>
                <input type="password" v-model="form.password" class="form-control form-control-sm">
            </div>
            <div class="form-group">
                <label for="">Université</label>
                <select name="" id="" v-model="form.university_id" class="form-control form-control-sm">
                <option value=""></option>
                <option  v-for="uni in universities"  v-bind:value="uni.id">
                {{ uni.name }}
                </option>
                </select>
            </div>
            <div>
            <label for="">Rôle</label>
            <select v-model="form.role" name="" id="" class="form-control form-control-sm">
            <option value=""></option>
            <option  v-for="role in roles"  v-bind:value="role" :key="role">
            {{ role }}
            </option>
            </select>
            </div>
            <div>
            <div class="error text-danger">
            {{ errorMessage }}
            </div>
            <button @click.prevent="save">Enregistrer</button>
            </div>
        </form>
    </div>
</template>

<script>
    export default {
        data(){
            return {
                form:{
                    name : "",
                    email : "",
                    password : "",
                    university_id : "",
                    role : "",
                    username : "",
                    telephone : "",
                },
                roles : ["ADMINISTRATEUR", "UNIVERISTE"],
                errorMessage : "",
            }
        },
        mounted(){
            this.initialize();
        },
        methods: {
            save(){
                this.errorMessage = "";
                this.postData("users", this.form)
                    .then(resp =>{
                        console.log(resp)
                        this.$emit('close')
                    })
                    .catch(error =>{
                        this.errorMessage = error.response.data.message
                        console.log(error)
                    })
            },
            initialize(){
                this.getData("university")
                    .then(response => {
                        this.$store.commit('setStateData', {
                            key: 'university',
                            value: response.data.data
                        })
                    })
                    .catch(error => {
                        console.error(error)
                    })
            },
            compareName(a, b) {
                const nameA = a.name.toUpperCase(); // ignore upper and lowercase
                const nameB = b.name.toUpperCase(); // ignore upper and lowercase
                if (nameA < nameB) {
                    return -1;
                }
                if (nameA > nameB) {
                    return 1;
                }

                // names must be equal
                return 0;
            }
          
        },
        computed: {

            universities() {
                return this.$store.getters.fetchData('university').sort(this.compareName)
            },
        }
        
    }
</script>

<style lang="scss" scoped>

</style>