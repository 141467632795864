<template>
    <div>
        <h1>File</h1>
       
        <div class="container">
        <tree-testvue/>
        
        </div>
    </div>
</template>

<script>

import TreeTestvue from '../components/Test/TreeTestvue.vue'
// OR THE FOLLOWING IMPORT FOR VUE 2
// import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed'
export default {
    components: {

        TreeTestvue,
    },
    data() {
        return {
          
        }
    },
}
</script>

<style scoped>
.container{
    width: 80%;
}
</style>