<template>
    <div>
        <h3 class="text-center my-4">Liste des Institus</h3>
        <modal-component :modalActive="isShowForm" @close="close">
            <form-instut @close="close" />
        </modal-component>

        <table-liste-simple
            :items="items"
            :headers="headers"
            tableUrl="institut"
            @deleteElement="get"
            @addElement="showForm"
        />
    </div>
</template>

<script>
import ModalComponent from "../global/ModalComponent.vue";
import TableListeSimple from "../global/TableListeSimple.vue";
import FormInstut from "./FormInstut.vue";
export default {
    components: { TableListeSimple, ModalComponent, FormInstut },
    data() {
        return {
            isShowForm: false,
        };
    },
    mounted() {
        this.get();
    },
    methods: {
        close() {
            this.showForm();
            this.get();
        },
        showForm() {
            this.isShowForm = !this.isShowForm;
        },
        get() {
            this.getData("institut")
                .then((response) => {
                    this.$store.commit("setStateData", {
                        key: "institut",
                        value: response.data,
                    });
                })
                .catch((error) => {
                    console.log(error);
                });
        },
    },
    computed: {
        items() {
            return this.$store.getters.fetchData("institut");
        },
        headers() {
            return [
                {
                    text: "#",
                    value: "id",
                },
                {
                    text: "name",
                    value: "name",
                },
                {
                    text: "description",
                    value: "description",
                },
                {
                    text: "faculte",
                    value: "faculte.name",
                },
                {
                    text: "opération",
                    value: "operation",
                },
            ];
        },
    },
};
</script>

<style scoped></style>
