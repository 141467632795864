<template>
    <div class="col">
        <div
            class="card border border-dark mb-3"
            @click="openUrl(livre.file_url)"
            style="max-width: 18rem"
        >
            <div class="card-header">{{ livre.title }}</div>
            <div class="card-body text-dark">
                <h5 class="card-title">{{ livre.title }}</h5>
                <p class="card-text">
                    {{ livre.description }}
                </p>
                <ul class="list-group list-group-light list-group-small">
                    <li class="list-group-item px-4">
                        {{ livre.department?.name }}
                    </li>
                    <li class="list-group-item px-4">
                        {{ livre.university?.name }}
                    </li>
                </ul>
                <a @click="openUrl(livre.file_url)" class="text-center btn btn-success"
                    >Ouvrir le livre</a
                >
            </div>
        </div>
    </div>
    <!-- <div class="col" @click="openUrl(livre.file_url)">
        <div>
            <span>Titre</span>
            <span
                ><b>{{ livre.title }}</b></span
            >
        </div>
        <div>
            <span>Déscription</span>
            <p>
                {{ livre.description }}
            </p>
        </div>
        <div>
            <span>Département</span>
            <span>
                
            </span>
        </div>
        <div>
            <span>Université</span>
            <span>
                
            </span>
        </div>
        <div></div>
    </div> -->
</template>

<script>
export default {
    props: ["livre"],
    methods: {
        openUrl(url) {
            window.open(url, "_blank");
        },
    },
};
</script>

<style scoped></style>
