<template>
    <div>
        <header-page />
      
       <h3 class="text-center"> {{ $route.params.nameUniversiry }}</h3>
       <div v-for="livre in livres ">
              <div>
                    <div
                    class="list-group-item px-3 rounded-1 list-group-item-success mb-2 border-start border-success border-5  universite
                "
                    style="cursor: pointer"
                    >
                    <a :href="livre.file_url" target="_blank">
                          <div class="row">
                            <div class="col-10">
                             
                                <h4> Titre : {{ livre.title }}</h4>
                                 <div class="col-12">
                                    <span>Déscription</span>
                                      <br /> 
                                    {{ livre.description }}
                                </div>

                                <div class="col-12">
                                   <span>Date de publication </span> {{ new Date(livre.created_at).toDateString() }}
                                </div>
                            </div>
                            <div class="col-2 tex-right" >
                                <img :src="pngtree" alt="" class="img-fluid" style="width: 100px">
                            </div>
                           
                        </div>

                    </a>
    
                </div>
            </div>
       </div>
    </div>
</template>

<script>
import HeaderPage from '../components/web/HeaderPage.vue'

import pngtree from '../assets/pngtree-book-png-image_1455811.jpg';
export default {
    components: { HeaderPage },
    data() {
        return {
            pngtree : pngtree,
            livres : [],
        }
    },
    mounted() {
        this.get(this.$route.params.id);
    },
    methods: {
        get(university_id) {
            this.getData("university_detail/"+ university_id)
                .then(resp => {
                    this.livres = resp.data
                    
                })
                .catch(err => { 
                    console.log(err);
                })

        }
    }
}
</script>

<style lang="scss" scoped></style>