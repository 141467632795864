<template>
    <div>
        <modal-component :modalActive="isShowForm" @close="close">
            <form-categorie @close="close" :selectItem="selectItem" />
        </modal-component>
        <table-liste-simple :headers="headers" :items="facultes" tableUrl="category-livre" @deleteElement="get"
            @editElement="editElement" @addElement="showForm" />
    </div>
</template>

<script>
import ModalComponent from '../global/ModalComponent.vue';
import TableListeSimple from '../global/TableListeSimple.vue'
import FormCategorie from './FormCategorie.vue';

export default {
    components: { TableListeSimple, ModalComponent, FormCategorie },
    props: ["selectItem"],
    data() {
        return {
            isShowForm: false,
            selectItem: null,
        }
    },
    mounted() {
        this.get();
    },
    methods: {
        close() {
            this.get();
            this.isShowForm = false;
        },
        showForm() {
            this.isShowForm = !this.isShowForm
        },
        editElement(item) {
            this.showForm();
            this.selectItem = item;
        },
        get() {

              this.getData("category-livre")
                .then((response) => {
                    this.$store.commit("setStateData", {
                        key: "categoryLivre",
                        value: response.data.data,
                    });
                })
                .catch((error) => {
                    console.log(error);
                });
           
        }
    },
    computed: {
        facultes() {
            return this.$store.getters.fetchData('categoryLivre')
        },
        headers() {
            return [
                {
                    text: '#',
                    value: 'id'
                },
                {
                    text: 'name',
                    value: 'name'
                },
                // {
                //     text: 'description',
                //     value: 'description'
                // },
                // {
                //     text: 'Université',
                //     value: 'university.name'
                // },
                {
                    text: 'operation',
                    value: 'operation'
                },
            ]
        }
    }


}
</script>

<style lang="scss" scoped></style>