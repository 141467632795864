<template>
    <div class="data-table">
        <div class="text-right">
            <span class="serchspan">Search field:</span>
            <select class="searchitem" v-model="searchField">
                <option></option>
                <option v-for="h in headers" v-bind="h">{{ h }}</option>

            </select>

            <span class="serchspan">Search value: </span>
            <input class="searchitems" type="text" v-model="searchValue">

            <button class="btn-add" @click="addElement">Ajouter</button>
        </div>

        <EasyDataTable :headers="headers" :items="items" :search-field="searchField" :search-value="searchValue"
            buttons-pagination table-class-name="customize-table" :rows-per-page="10" alternating>
            <template #item-university="item">
                <span>{{ item?.university?.name }}</span>
            </template>
            <template #item-operation="item">
                <div class="operation-button">
                    <button @click="editElement(item)" title="Modifier">
                        <img :src="edit" alt="" class="table-icon" />
                    </button>
                    <button @click="deleteElement(item)" title="Supprimer">
                        <img :src="deleteIcone" alt="" class="table-icon" />
                    </button>
                    <!-- <button>
            <img :src="show" alt="" class="table-icon" />
            </button> -->
                </div>
            </template>
        </EasyDataTable>
    </div>
</template>

<script>
export default {
    props: ['headers', 'items', 'tableUrl'],
    data() {
        return {
            searchField: '',
            searchValue: ""

        }
    }
    , methods: {
        async deleteElement(item) {
            const { value: response } = await this.confirmDelete('Suppression', "Voulez-vous vraiment supprimer ? ", 'OUI')
            if (response) {
                this.deleteData(`${ this.tableUrl }/${ item.id }`);
            }
            this.$emit('deleteElement', item)
        },
        editElement(item) {
            this.$emit('editElement', item)
        },
        addElement() {
            this.$emit('addElement')
        }

    }

}
</script>
