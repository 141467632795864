<template>
    <div>
        <modal-component :modalActive="isShowForm" @close="close" >
            <form-tag @close="close" :selectItem="selectItem"/>
        </modal-component>
        <table-liste-simple :headers="headers" :items="tags" tableUrl="tags" @deleteElement="get"
            @editElement="editElement" @addElement="showForm" />
    </div>
</template>

<script>
import ModalComponent from '../global/ModalComponent.vue';
import TableListeSimple from '../global/TableListeSimple.vue'
import FormTag from './FormTag.vue';

export default {
    components: { TableListeSimple, ModalComponent, FormTag },
    data() {
        return {
            isShowForm: false,
            selectItem: null,
        }
    },
    mounted() {
        this.get();
    },
    methods: {
        close() {
            this.get();
            this.isShowForm = false;
        },
        showForm() {
            this.isShowForm = !this.isShowForm
        },
        editElement(item) {
            this.showForm();
            this.selectItem = item;
        },
        get() {
            this.getData('tags')
                .then(response => {

                    this.$store.commit('setStateData', {
                        key: 'tags',
                        value: response.data.data
                    })
                })
        }
    },
    computed: {
        tags() {
            return this.$store.getters.fetchData('tags')
        },
        headers() {
            return [
                {
                    text: '#',
                    value: 'id'
                },
                {
                    text: 'description',
                    value: 'description'
                },
                // {
                //     text: 'description',
                //     value: 'description'
                // },
                {
                    text: 'Domaine',
                    value: 'faculte.name'
                },
                {
                    text: 'operation',
                    value: 'operation'
                },
            ]
        }
    }


}
</script>

<style lang="scss" scoped></style>